
    import Question from "@/components/question.vue";

    import "@/ts/extensions/ArrayExtensions";
    import { IQuestionInterface } from "@/ts/quiz/IQuestionInterface";
    import { IQuizInterface } from "@/ts/quiz/IQuizInterface";

    import { Component, Prop, Vue } from "vue-property-decorator";

    // Enums
    enum QuizState {
        Intro = 0,
        Questions,
        Results
    }

    // From https://www.raymondcamden.com/2018/01/22/building-a-quiz-with-vuejs
    @Component({
        components: {
            Question,
        },
    })

    // *****************************************************************************************************************
    export default class Quiz extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        private state: QuizState                = QuizState.Intro;

        private title: string                   = '';
        private failedQuestions: string[]       = [];
        private questionIndex: number           = 0;
        private correctAnswers: number          = 0;
        private percentage: string              = '';

        // Properties --------------------------------------------------------------------------------------------------
        @Prop() private readonly model!: IQuizInterface;
        @Prop() private readonly iconPathAndName!: string;

        // Methods -----------------------------------------------------------------------------------------------------
        public created() {
            // This is the earliest point that we can call methods at, so this is where we populate our answers array.
            this.populateAllAnswers();
        }

        public mounted() {
            this.title = this.model.title;
            this.changeToState(QuizState.Intro);
        }

        private onStartQuiz(): void {
            this.changeToState(QuizState.Questions);
        }

        private onRestartQuiz(): void {
            this.changeToState(QuizState.Questions);
        }

        private changeToState(state: QuizState) {
            if (this.state === state) {
                return; // Already in this state
            }

            switch (state) {
                case QuizState.Intro:
                    break;

                case QuizState.Questions:
                    this.model.questions.shuffle();
                    this.shuffleAnswers();
                    this.correctAnswers = 0;
                    this.questionIndex  = 0;
                    break;

                case QuizState.Results:
                    this.showResults();
                    break;
            }

            this.state = state;
        }

        private populateAllAnswers(): void {
            this.model.questions.forEach((question) => {
                question.answers = question.facts.concat(question.falsehoods);
            });
        }

        private shuffleAnswers(): void {
            this.model.questions.forEach((question) => {
                question.answers.shuffle();
            });
        }

        private onAnswer(event: Question): void {
            this.model.questions[this.questionIndex].correctlyAnswered = event.correctlyAnswered;
            if ((this.questionIndex + 1) === this.model.questions.length) {
                this.changeToState(QuizState.Results);
            } else {
                this.questionIndex++;
            }
        }

        private showResults(): void {
            this.failedQuestions            = [];
            let failedQuestionIndex: number = 0;
            this.model.questions.forEach((question) => {
                if (question.correctlyAnswered) {
                    this.correctAnswers++;
                } else {
                    this.failedQuestions[failedQuestionIndex++] = question.text;
                }
            });
            this.percentage = ((this.correctAnswers / this.model.questions.length) * 100).toFixed(0);
        }

        // Data --------------------------------------------------------------------------------------------------------
        private data() {
            return {
                QuizState,
            };
        }
    }
