
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { IQuestionInterface } from '../ts/quiz/IQuestionInterface';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class Question extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        public correctlyAnswered: boolean   = false;
        private chosenAnswers: string[]     = [];

        // Properties --------------------------------------------------------------------------------------------------
        @Prop() private readonly index!: number;
        @Prop() private readonly totalQuestions!: number;
        @Prop() private readonly model!: IQuestionInterface;

        // Methods -----------------------------------------------------------------------------------------------------
        private submitAnswer(): void {
            this.correctlyAnswered = this.checkAnswers();
            this.$emit('answer-event', { correctlyAnswered: this.correctlyAnswered });
            this.chosenAnswers = [];
        }

        private checkAnswers(): boolean {
            if (this.chosenAnswers.length !== this.model.facts.length) {
                return false;   // Too few/many answers
            }

            // There is no way to stop or break a forEach() loop other than by throwing an exception
            let missingFact: boolean = false;
            this.model.facts.forEach((fact) => {
                if (this.chosenAnswers.includes(fact) === false) {
                    missingFact = true; // A fact was missing from the answers given
                }
            });
            if (missingFact) {
                return false;
            }

            // All facts and only the facts were present, so the answers are deemed correct
            return true;
        }

        private data() {
            return {
                formula: '$$x = 2.$$',
            };
        }
    }
