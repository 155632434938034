declare global {
    // tslint:disable
    interface Array<T> {
        shuffle(): void;
    }
    // tslint:enable
}

if (!Array.prototype.shuffle) {
    Array.prototype.shuffle = function<T>(this: T[]): void {
        // Fisher-Yates shuffle, walk the array in the reverse order and swap each element with a raondom one before
        // it.
        for (let index = this.length - 1; index > 0; index--) {
            const randomIndex = Math.floor(Math.random() * (index + 1));  // Random index from 0 to index

            // Swap elements at [index] and [randomIndex]. We use "destructuring assignment" syntax to achieve this.
            [this[index], this[randomIndex]] = [this[randomIndex], this[index]];
        }
    };
}

export {};
